import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`És característic d’aquest bolet el color del capell verd blavós clar, encara que també pot aparèixer color marró ocraci argilós o crema ocraci. El capell és convex tirant a pla de 4-8 cm de diàmetre i pot tenir els marges aixecats. Les làmines de davall són primes, no gaire atapeïdes de color un poc més clar que el capell. El peu és cilíndric, blanc, de 2 a 5 cm x 4 a 10 mm, un poc corbat i normalment més ample a la base. Les espores són blanques en massa, el·líptiques, de 6-7 x 3-4 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      